import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

if (window.location.pathname.startsWith('/noise-cancellation')) {
  import('./bns/BNSWrapper').then(({ BNSWrapper }) => {
    root.render(<BNSWrapper />);
  });
} else if (window.location.pathname.startsWith('/signup')) {
  import('./self_serve_new/SelfServeWrapper').then(({ SelfServeWrapper }) => {
    root.render(<SelfServeWrapper />);
  });
} else {
  import('./app').then(({ AppWrapper }) => {
    root.render(<AppWrapper />);
  });
}

reportWebVitals();
